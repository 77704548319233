import React, { useEffect, useRef, useState } from 'react';
import fullpage from 'fullpage.js';
import 'fullpage.js/dist/fullpage.css';
import Footer from '../layout/Footer';
import Banner from '../components/Banner';
import GambetSec from '../components/GambetSec';
import RafflesSec from '../components/RafflesSec';
import RoadmapSec from '../components/Roadmap';
import { Link } from 'react-router-dom';
import HowAreWeSec from '../components/HowAreWeSec';
import FaqSec from '../components/FaqSec';
import Communitysec from '../components/Communitysec';
import Deflationary from '../components/Deflationary';
import Tablesec from '../components/Tablesec';
import Tokenomics from '../components/Tokenomics';
import { fadeInUp, zoomIn } from '../animation/FadeInUp';
import SplitType from 'split-type';
import gsap from 'gsap';
import Deflationaryabout from '../components/Deflationaryabout';
import TotalSupply from '../components/TotalSupply';


const About = () => {

    const oddsTextRef = useRef(null);

    useEffect(() => {
        const split = new SplitType('.buygembet', { types: 'chars,words' });

        const defaults = gsap.timeline();
        defaults.set(".buygembet .char", { opacity: 0, y: 20, skewY: 10, duration: 0 });

        const applyTextAnimation = (textRef, delay) => {
            const chars = textRef.querySelectorAll('.char');

            const tl = gsap.timeline({ delay: delay });

            tl.to(
                chars,
                {
                    opacity: 1,
                    y: 0,
                    skewY: 0,
                    stagger: { amount: 0.4, each: delay }, // Apply delay to each character
                    duration: 1,
                }
            );
        };

        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const targetRef = entry.target;
                    if (targetRef === oddsTextRef.current) {
                        fadeInUp(".whygembet-title", { duration: 0.5, delay: 1 });
                        applyTextAnimation(targetRef, 1); // Adjust delay here for oddsTextRef
                    }

                    observer.unobserve(targetRef); // Stop observing after animation is triggered once
                }
            });
        }, observerOptions);

        if (oddsTextRef.current) {
            observer.observe(oddsTextRef.current);
        }
      

        return () => {
            if (oddsTextRef.current) {
                observer.unobserve(oddsTextRef.current);
            }
        
        };
    }, []);

    useEffect(() => {
        new fullpage('#aboutpage', {
            sectionsColor: ['#040517', '#040517', '#040517', '#040517', '#040517'],
            anchors: ['deflationaryabout', 'tiers-and-benefits', 'tokenomics','total-supply', 'faqs', 'footer'],
            responsiveWidth: 768,
            scrollOverflow: true,
            afterLoad: function (origin, destination, direction) {
                if (destination.index === 1) {
                    console.log("Started...");
                }
            },
        });
    }, []);

    useEffect(() => {
        const removeWatermarkDiv = () => {
            const watermarkParent = document.querySelector('.fp-watermark');
            if (watermarkParent) {
                watermarkParent.remove();
            }
        };

        window.addEventListener('load', removeWatermarkDiv);
    }, []);


    return (
        <div id="aboutpage">
            <div className="section">
                <div className='about-us-main '>
                    <div className='container'>
                        <div  className='row justify-content-center'>
                            <div className='col-md-10 col-12'>
                                <div class="section-title-area  text-center position-relative">
                                    <h2 class="gradientText whygembet-title">why buy gambet?</h2>
                                    <p class="text-md-light text-center buygembet" ref={oddsTextRef}>$GAMBET is the native utility token powering the ecosystem.Based on the duration and amount of the GAMBET tokens you stake on the platform, you will be awarded a tier level.This will give you additional benefits when participating in premier raffle competitions. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Deflationaryabout />
            </div>
            <div className='section'>
                <Tablesec />
            </div>
            <div className='section'>
                <Tokenomics />
            </div>
            <div className='section'>
                <TotalSupply />
            </div>
            <div className="section">
                <FaqSec />
            </div>
            <div className="section">
                <div className="scrollable-content">
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default About;
