import React, { Fragment, useEffect, useRef } from 'react';
import gsap from 'gsap';
import SplitType from 'split-type';
import { faceZoomInUp, fadeInUp, zoomIn } from '../animation/FadeInUp';
import { Container, Image, Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';


const RafflesSec = () => {
    const oddsTextRef = useRef(null);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const targetRef = entry.target;
                    if (targetRef === oddsTextRef.current) {
                        fadeInUp(".title-refales", { duration: 0.5, delay: 0.5 });
                        zoomIn(".image-gembet-raf", { duration: 0.5, delay: 1.2 });
                    } 

                    observer.unobserve(targetRef); // Stop observing after animation is triggered once
                }
            });
        }, observerOptions);

        if (oddsTextRef.current) {
            observer.observe(oddsTextRef.current);
        }
    

        return () => {
            if (oddsTextRef.current) {
                observer.unobserve(oddsTextRef.current);
            }
        };
    }, []);

    return (
        <>
            <div className='gambet-area' ref={oddsTextRef}>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-md-12'>
                            <div className='section-title-area small-shadow-space text-center position-relative'>
                                <h2 className='gradientText  title-refales' style={{opacity: 0}}>Our Amazing Raffles</h2>
                                <h3 className='shadow-text'>Raffle</h3>
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-8 col-xxxl-11'>
                            <img src={'/img/raffel-image.png'} style={{opacity: 0}} className="image-gembet-raf w-100 position-relative" alt="Gambling" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='client-logos-area'>
                <Container>
                    <Row className='justify-content-center'>
                        <div className='col-lg-10'>
                            <div className='section-title-area shapes-title-area position-relative faq-title'>
                                <Image src={'/img/title-left-shape.png'} className='ti-left-shape' />
                                <h3 className='gradientText text-center'>Our Backers and Partners</h3>
                                <Image src={'/img/title-right-shape.png'} className='ti-left-shape' />
                            </div>
                            <Swiper
                                slidesPerView={6}
                                spaceBetween={10}
                                className="mySwiper"
                                navigation={false} 
                                pagination={false}
                                loop={true}
                                modules={[Autoplay]}
                                autoplay={{
                                    delay: 1500,
                                    disableOnInteraction: false,
                                }}
                                breakpoints={{
                                    // when window width is >= 320px
                                    0: {
                                        slidesPerView: 2,
                                        spaceBetween: 20
                                    },
                                    375: {
                                        slidesPerView: 3,
                                        spaceBetween: 20
                                    },
                                    // when window width is >= 480px
                                    480: {
                                        slidesPerView: 3,
                                        spaceBetween: 30
                                    },
                                    // when window width is >= 640px
                                    768: {
                                        slidesPerView: 4,
                                        spaceBetween: 40
                                    },
                                    1024: {
                                        slidesPerView: 5,
                                        spaceBetween: 40
                                    }
                                }}
                            >
                                <SwiperSlide><div className='client-logo'><Image src={'/img/backers1.png'} /></div></SwiperSlide>
                                <SwiperSlide><div className='client-logo'><Image src={'/img/backers2.png'} /></div></SwiperSlide>
                                <SwiperSlide><div className='client-logo'><Image src={'/img/backers3.png'} /></div></SwiperSlide>
                                <SwiperSlide><div className='client-logo'><Image src={'/img/backers4.png'} /></div></SwiperSlide>
                                <SwiperSlide><div className='client-logo'><Image src={'/img/backers5.png'} /></div></SwiperSlide>
                                <SwiperSlide><div className='client-logo'><Image src={'/img/backers6.png'} /></div></SwiperSlide>
                                <SwiperSlide><div className='client-logo'><Image src={'/img/backers1.png'} /></div></SwiperSlide>
                                <SwiperSlide><div className='client-logo'><Image src={'/img/backers2.png'} /></div></SwiperSlide>
                                <SwiperSlide><div className='client-logo'><Image src={'/img/backers3.png'} /></div></SwiperSlide>
                                <SwiperSlide><div className='client-logo'><Image src={'/img/backers4.png'} /></div></SwiperSlide>
                                <SwiperSlide><div className='client-logo'><Image src={'/img/backers5.png'} /></div></SwiperSlide>
                                <SwiperSlide><div className='client-logo'><Image src={'/img/backers6.png'} /></div></SwiperSlide>
                                <SwiperSlide><div className='client-logo'><Image src={'/img/backers1.png'} /></div></SwiperSlide>
                                <SwiperSlide><div className='client-logo'><Image src={'/img/backers2.png'} /></div></SwiperSlide>
                                <SwiperSlide><div className='client-logo'><Image src={'/img/backers3.png'} /></div></SwiperSlide>
                                <SwiperSlide><div className='client-logo'><Image src={'/img/backers4.png'} /></div></SwiperSlide>
                                <SwiperSlide><div className='client-logo'><Image src={'/img/backers5.png'} /></div></SwiperSlide>
                                <SwiperSlide><div className='client-logo'><Image src={'/img/backers6.png'} /></div></SwiperSlide>
                                <SwiperSlide><div className='client-logo'><Image src={'/img/backers1.png'} /></div></SwiperSlide>
                                <SwiperSlide><div className='client-logo'><Image src={'/img/backers2.png'} /></div></SwiperSlide>
                                <SwiperSlide><div className='client-logo'><Image src={'/img/backers3.png'} /></div></SwiperSlide>
                                <SwiperSlide><div className='client-logo'><Image src={'/img/backers4.png'} /></div></SwiperSlide>
                                <SwiperSlide><div className='client-logo'><Image src={'/img/backers5.png'} /></div></SwiperSlide>
                                <SwiperSlide><div className='client-logo'><Image src={'/img/backers6.png'} /></div></SwiperSlide>
                            </Swiper>
                        </div>
                    </Row>
                </Container>
            </div>
        </>
    );
};


export default RafflesSec;
