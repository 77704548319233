import React, { Fragment, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { Container, Image, Row } from 'react-bootstrap';
import TextSplitAnimation from './WordWiseAnimation';
import Accordion from 'react-bootstrap/Accordion';
import SplitType from 'split-type';
import { fadeInUp, zoomIn } from '../animation/FadeInUp';
import { FreeMode, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';


const Tokenomics = () => {

    return (
        <>
            <div className='Tokenomics-area'>
                <Container>
                    <Row className='justify-content-center'>
                        <div className='col-md-7 col-12'>
                            <div className='section-title-area  text-center position-relative'>
                                <h2 className='gradientText title-anime'>Tokenomics</h2>
                                <h3 className='shadow-text'>TOKENOMICS</h3>
                                <p className='mt-4 mb-0 text-center text-md-light'>The $GAMBET token is at the heart of our platform, and its tokenomics have been thoughtfully designed to balance accessibility, liquidity, and responsible distribution. <br/><br/>Here's a breakdown of the key tokenomics details:</p>
                            </div>
                            <Image className='w-100 ' src={'/img/tokenomics.png'} />
                        </div>
                    </Row>
                </Container>
            </div>
        </>
    );
};


export default Tokenomics;
