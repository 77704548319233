import React, { useEffect } from 'react';
import { Routes } from 'react-router-dom';
import MyRoutes from './routes';
import AOS from 'aos';
import 'aos/dist/aos.css';


function App() {
    useEffect(() => {
        AOS.init();
      }, [])
    return (
        <>
            <MyRoutes />
        </>
    );
}

export default App;