import React, { Fragment, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { Container, Image, Row } from 'react-bootstrap';
import SplitType from 'split-type';
import { fadeInUp, zoomIn } from '../animation/FadeInUp';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';


const Deflationaryabout = () => {
    const oddsTextRef = useRef(null);

    useEffect(() => {
        const split = new SplitType('.odds-content-111', { types: 'chars,words' });

        const defaults = gsap.timeline();
        defaults.set(".odds-content-111 .char", { opacity: 0, y: 20, skewY: 10, duration: 0 });

        const applyTextAnimation = (textRef, delay) => {
            const chars = textRef.querySelectorAll('.char');

            const tl = gsap.timeline({ delay: delay });

            tl.to(
                chars,
                {
                    opacity: 1,
                    y: 0,
                    skewY: 0,
                    stagger: { amount: 0.4, each: delay }, // Apply delay to each character
                    duration: 1,
                }
            );
        };

        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const targetRef = entry.target;
                    if (targetRef === oddsTextRef.current) {
                        fadeInUp(".title-ref-def", { duration: 0.5, delay: 0.5 });
                        applyTextAnimation(targetRef, 1.5);
                        zoomIn(".image-zoomed", { duration: 1.7, delay: 2 });
                    }

                    observer.unobserve(targetRef); // Stop observing after animation is triggered once
                }
            });
        }, observerOptions);

        if (oddsTextRef.current) {
            observer.observe(oddsTextRef.current);
        }


        return () => {
            if (oddsTextRef.current) {
                observer.unobserve(oddsTextRef.current);
            }
        };
    }, []);
    return (
        <>
            <Image src={'/img/defi-shadow-shape.png'} className='def-shadow' width={'300'} height={'300'} />
            <div className='defi-area position-relative '>
                <div className='def-sec-box section-title-area'>
                    <h2 className='gradientText title-ref-def' style={{ opacity: 0 }}>$GAMBET is deflationary</h2>
                    <p className='text-md-light odds-content-111' ref={oddsTextRef}>The gameplay aims to prevent $GAMBET inflation. Participants buy and stake $GAMBET for additional benefits when participating in premier raffle competitions, boosting buy pressure and reducing sell pressure. A strategic buyback, funded from ticket sales, winning prizes, and a token tax, controls sell pressure.<br/><br/>This encourages community confidence in buying and staking $GAMBET, leading to higher-tier rewards and increased odds in raffles, contributing to the token's value appreciation as the game expands.</p>
                </div>
                <Image src={'/img/deflationary.png'} style={{ opacity: 0 }} className='def-image image-zoomed w-md-100' width={'300'} height={'300'} />
            </div>
        </>
    );
};


export default Deflationaryabout;
