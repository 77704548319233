import { BrowserRouter as Router, Routes, Route, Form } from "react-router-dom";
import React from 'react'
import Layout from "../layout/Layout";
import HomePage from "../pages/HomePage";
import About from "../pages/About";

function MyRoutes() {

    return (

        <Router>
            <Routes>
                <Route path="/" exact element={<Layout><HomePage /></Layout>} />
                <Route path="/home" exact element={<Layout><HomePage /></Layout>} />
                <Route path="/about-us" exact element={<Layout><About /></Layout>} />
                {/* <Route path='*' element={<NotFound />} /> */}
            </Routes>
        </Router>

    )
}

export default MyRoutes;