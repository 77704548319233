import { Container, Nav, NavDropdown, Navbar, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const Header = () => {
    return (
        <>
            <Navbar expand="lg" className="bg-transparent header-menu"  data-bs-theme="dark">
                <Container>
                    <Navbar.Brand href="/#gambet"><img src="img/logo.png" className="header-logo" /></Navbar.Brand>
                    {/* <Link to="/#gambet" className="navbar-brand"><img src="img/logo.png" className="header-logo" /></Link> */}
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mx-auto">
                            <Nav.Link href="https://docsend.com/view/repicppemfrx6uw9" target="_blank">Whitepaper</Nav.Link>
                            <Nav.Link href="/about-us#tokenomics">tokenomics</Nav.Link>
                            <Nav.Link href="/#roadmap">Roadmap</Nav.Link>
                            <Nav.Link href="#faqs">FAQs</Nav.Link>
                        </Nav>
                        <Nav className="ml-auto">
                            <Link target="_blank" to={'https://game.gambet.io/'} className="button-primary">Play</Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default Header;
