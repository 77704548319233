import React, { Fragment, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { Container, Image, Row } from 'react-bootstrap';
import TextSplitAnimation from './WordWiseAnimation';
import Accordion from 'react-bootstrap/Accordion';
import SplitType from 'split-type';
import { fadeInUp, zoomIn } from '../animation/FadeInUp';
import { FreeMode, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';


const TotalSupply = () => {

    return (
        <>
            <div className='totalspply-area'>
                <Container>
                    <Row className='justify-content-center'>
                        <div className='col-md-7 col-12'>
                            <div className='section-title-area  text-center position-relative'>
                                <h2 className='gradientText title-anime'>Total Supply</h2>
                                <p className=' text-md-light mt-md-4 text-center'>The total supply of $GAMBET tokens is set at 10 billion (10,000,000,000). This carefully chosen figure strikes a balance between ensuring accessibility for a wide community of participants and maintaining liquidity while preventing excessive token concentration.</p>
                            </div>
                        </div>
                    </Row>
                    <Row className='justify-content-center'>
                        <div className='col-md-7 col-12'>
                        <div className='position-relative  mt-5' >
                                <table className='striped text-center main-border'>
                                    <tbody>
                                        <tr>
                                            <th className='gradient'>Address</th>
                                            <td>
                                                <div className='table-td d-block '>
                                                    <div className='table-dec'>zxfsdfsdfsdfsd</div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className='gradient'>Token Symbol</th>
                                            <td>
                                                <div className='table-td d-block '>
                                                    <div className='table-dec'>$GAMBET</div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className='gradient'>Network</th>
                                            <td>
                                                <div className='table-td d-block '>
                                                    <div className='table-dec'>BNB-Chain</div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className='gradient'>Decimal</th>
                                            <td>
                                                <div className='table-td d-block '>
                                                    <div className='table-dec'>18</div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ backgroundImage: 'url(/img/tablebg.png)' }} className='shape-imagetable' />
                            </div> 
                        </div>
                    </Row>
                </Container>
            </div>
        </>
    );
};


export default TotalSupply;
